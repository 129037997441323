import t from './actionTypes'
import Cookies from 'js-cookie'

export function setCookieValue(key, value = '') {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }

  Cookies.set(`eaze_${key}`, value, {
    path: '/',
    expires: 1825 // 5 years in days
  })
}

export function setCookie(cookies) {
  Object.entries(cookies).forEach(([key, value]) => setCookieValue(key, value))

  return {
    type: t.SET_COOKIE,
    cookies
  }
}

// this is only ever done client side (componentDidMount) in hocs/page
export function rehydrateCookies(dispatch) {
  const jar = {}

  Object.entries(Cookies.get()).forEach(([key, value]) => {
    if (key.match(/eaze_/)) {
      let valueToStore = null

      try {
        valueToStore = JSON.parse(value)
      } catch (err) {
        valueToStore = value
      }

      key = key.replace('eaze_', '').trim()

      jar[key] = valueToStore
    }
  })

  dispatch({ type: t.COOKIE_REHYDRATE, cookies: jar })
}
