import t from './actionTypes'

export const defaultState = {
  collection: {},
  map: {}
}

const productsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_PRODUCTS:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.payload
        }
      }

    case t.SET_PRODUCT_ID_MAP:
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload
        }
      }

    default:
      return state
  }
}

export default productsReducer
