import t from 'redux/modal/actionTypes'

export const defaultState = {
  referral: {
    open: false
  },
  isPersonaModalOpen: false,
  iOSvapesRedirect: {
    open: false,
    redirectPath: ''
  }
}

export type ModalState = {
  referral: {
    open: boolean
  }
  isPersonaModalOpen: boolean
  iOSvapesRedirect: {
    open: boolean
    redirectPath: string
  }
}

const nonAlertModals = (state: ModalState = defaultState, action: { type: string; payload?: string }): ModalState => {
  switch (action.type) {
    case t.OPEN_IOS_VAPES_REDIRECT_MODAL:
      return {
        ...state,
        iOSvapesRedirect: {
          open: true,
          redirectPath: action.payload
        }
      }
    case t.CLOSE_IOS_VAPES_REDIRECT_MODAL:
      return {
        ...state,
        iOSvapesRedirect: {
          open: false,
          redirectPath: ''
        }
      }
    case t.TOGGLE_REFERRAL_MODAL:
      return {
        ...state,
        referral: {
          open: !state.referral.open
        }
      }

    case t.OPEN_PERSONA_MODAL:
      return {
        ...state,
        isPersonaModalOpen: true
      }

    case t.CLOSE_PERSONA_MODAL:
      return {
        ...state,
        isPersonaModalOpen: false
      }
    default:
      return state
  }
}

export default nonAlertModals
