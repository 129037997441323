import t from './actionTypes'

export const defaultState = {
  items: {},
  hasNoPreviousDeliveries: false
}

const deliveries = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.HAS_NO_PREVIOUS_DELIVERIES:
      return {
        ...state,
        hasNoPreviousDeliveries: true
      }

    case t.SET_DELIVERIES:
      return {
        ...state,
        items: payload
      }

    case t.RESET_DELIVERIES:
      return defaultState

    default:
      return state
  }
}

export default deliveries
