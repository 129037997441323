import t from './actionTypes'

export const defaultState = {
  error: false,
  loading: true,
  groups: {}
}

const cityPage = (state = defaultState, { type, payload = {} }) => {
  switch (type) {
    case t.SET_CITYPAGE_GROUP:
      return {
        ...state,
        groups: {
          ...state.groups,
          ...payload
        }
      }
    case t.CITYPAGE_GROUP_LOADING:
    case t.CITYPAGE_GROUP_LOADED:
    case t.SET_CITYPAGE_ERROR:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}

export default cityPage
