import t from './actionTypes'

export const defaultState = {
  coordinates: {},
  hasVerifiedAge: false,
  loggedin: false,
  token: '',
  userId: '',
  placeId: ''
}

const cookieReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case t.COOKIE_REHYDRATE:
      return {
        ...state,
        ...action.cookies
      }

    case t.SET_COOKIE:
      return {
        ...state,
        ...action.cookies
      }

    default:
      return state
  }
}

export default cookieReducer
