import t from './actionTypes'

export const ARTICLE_DEFAULT = {
  excerpt: { rendered: '' },
  content: { rendered: '' },
  title: { rendered: '' },
  author_meta: {},
  image: '',
  slug: '',
  id: 0
}

export const defaultState = {
  primaryArticle: ARTICLE_DEFAULT,
  secondaryArticle: ARTICLE_DEFAULT,
  tertiaryArticles: [],
  currentArticle: ARTICLE_DEFAULT,
  groupId: null
}

const articles = (state = defaultState, action = {}) => {
  switch (action.type) {
    case t.SET_ARTICLE_GROUP_ID:
      return {
        ...state,
        groupId: action.payload
      }

    default:
      return state
  }
}

export default articles
